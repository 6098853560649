<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <section></section>
    <br>
    <!-- caixa de mensagem -->
    <section class="col-md-12 pm-card mensagem-caixa">
      <div
        class="col-md-3 cc"
        @click="turmasProf()"
        style="cursor: pointer"
      >
        <div class="tile-03 tile-f mb-5">
          <h3>Gerência de Turmas</h3>
          <p>Clique aqui para gerenciar as Turmas</p>
        </div>
      </div>
      <h2 class="titulo-caixa">Caixa de Mensagens</h2>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8 lg:col-2">
          <pm-Button @click="enviarMensagemModal" label="Enviar Mensagem" class="p-button-danger" />
          <br><br>
          <pm-Menu :model="items" style="width:100%!important;" />

        </div>
        <div class="field col-10 md:col-10 lg:col-10">
          <pm-TabView v-if="recebidas == 1">
            <pm-TabPanel header="Não Lidas">
              <div>
                <listaDeMensagem :env="0" :msgAll="msgAll" :links="links" @abrirMensagem="abrirMensagem" @paginas="paginas" />
              </div>
            </pm-TabPanel>
            <pm-TabPanel header="Lidas">
              <div>
                <listaDeMensagem :env="0" :msgAll="msgAll2" :links="links2" @abrirMensagem="abrirMensagem"  @paginas="paginasLidas" />
              </div>
            </pm-TabPanel>
          </pm-TabView>

          <div v-else-if="recebidas==0">
            <listaDeMensagem :env="1" :msgAll="msgAll" :links="links" @abrirMensagem="abrirMensagem"  @paginas="paginas" />
          </div>
        </div>
      </div>
    </section>

    <pm-Dialog header="Envio de Mensagem" v-model:visible="popmsg2" :style="{width: '100vw'}" :maximizable="true" :modal="true">
      <div class="p-fluid formgrid grid" >
        <pm-TabView class="field col-12">
          <pm-TabPanel header="Mensagem Individual">

            <div class="field col-12 md:col-12 lg:col-12" >
              <label style="font-weight: 400 !important;margin-top: 15px;">  </label>
              <span class="p-input-icon-left ">
                <i class="pi pi-search" />
                <pm-AutoComplete v-model="pesquisaservidor" :suggestions="filterservidor" @complete="pesquisarServidor" field="nome_completo"
                  placeholder="Pesquisar Nome do Servidor"
                />
              </span>
            </div>

            <div class="field col-12 md:col-12 lg:col-12">
              <label>Assunto</label>
              <pm-InputText  v-model="mensagem.titulo" />
            </div>
            <div class="field col-12 md:col-12">
              <pm-Textarea v-model="mensagem.msg" rows="5" cols="30" />
            </div>
            <div class="field col-2">
              <pm-Button  label="Enviar" icon="pi pi-check" @click="enviarMensagem(1,'n')" autofocus />
            </div>
          </pm-TabPanel>

          <pm-TabPanel header="Mensagem ao Setor" >
            <div class="field col-12 md:col-5 lg:col-5" >
              <label>Setor</label>
              <select class="p-inputtext p-component" v-model="mensagem.setor"
                style="appearance: revert !important;width: 100% !important;" >
                <option  value="3" >Secretária</option>
                <option  value="7" >Coordenacao pedagogica</option>

              </select>
            </div>

            <div class="field col-12 md:col-12 lg:col-12">
              <label>Assunto</label>
              <pm-InputText  v-model="mensagem.titulo" />
            </div>
            <div class="field col-12 md:col-12">
              <pm-Textarea v-model="mensagem.msg" rows="5" cols="30" />
            </div>
            <div class="field col-2">
              <pm-Button  label="Enviar" icon="pi pi-check" @click="enviarMensagem(2,'n')" autofocus />
            </div>
          </pm-TabPanel>
        </pm-TabView>
      </div>
    </pm-Dialog>


    <pm-Dialog  v-model:visible="popmsg" :style="{width: '50vw'}" :maximizable="true" :modal="true">
      <template #header>
        <h3>Messagens</h3>
      </template>


      <div v-if="conteudo_mensagem.servidor_id == id_servidor_logado" class="container darker">
        <h5>Título: {{conteudo_mensagem.titulo}}</h5>
        <span class="badge badge-prym" style="float:right" v-if="conteudo_mensagem.servidor_nome_enviou != '' ">{{conteudo_mensagem.servidor_nome_enviou}}</span>
        <p><strong>Mensagem:</strong> {{conteudo_mensagem.msg}}</p>
        <span class="time-right">{{conteudo_mensagem.hora}}</span>
      </div>

      <div v-else class="container">
        <h5>Título: {{conteudo_mensagem.titulo}}</h5>
        <span class="badge badge-prym" v-if="conteudo_mensagem.servidor_nome_enviou != '' ">{{conteudo_mensagem.servidor_nome_enviou}}</span>
        <p><strong>Mensagem:</strong> {{conteudo_mensagem.msg}}</p>
        <span class="time-left">{{conteudo_mensagem.hora}}</span>
      </div>

      <div  v-for="respMsg in conteudo_mensagem.respostas_da_msg" :key="respMsg.id">

        <div v-if="id_servidor_logado == respMsg.servidor_id" class="container darker">
          <h5>Título: {{respMsg.titulo}}</h5>
          <span class="badge badge-prym right"   style="float:right" v-if="respMsg.servidor_nome != '' ">{{respMsg.servidor_nome}}</span>

          <p><strong>Mensagem:</strong> {{respMsg.msg}}</p>
          <span class="time-right">{{respMsg.hora}}</span>
        </div>

        <div v-else class="container">
          <h5>Título: {{respMsg.titulo}}</h5>
          <span class="badge badge-prym right"  v-if="respMsg.servidor_nome != '' ">{{respMsg.servidor_nome}}</span>
          <p><strong>Mensagem:</strong> {{respMsg.msg}}</p>
          <span class="time-left">{{respMsg.hora}}</span>
        </div>


      </div>

      <div class="p-fluid formgrid grid" v-if="responder_msg" >
        <div class="field col-12 md:col-12 lg:col-12" >
          <hr>
          <h3>
            Responder Mensagem
          </h3>
        </div>

        <div class="field col-12 md:col-12 lg:col-12">
          <label>Assunto</label>
          <pm-InputText  v-model="mensagem.titulo" />
        </div>
        <div class="field col-12 md:col-12">
          <pm-Textarea v-model="mensagem.msg" rows="5" cols="30" />
        </div>
        <div class="field col-2">
          <pm-Button  label="Enviar" icon="pi pi-check" @click="enviarMensagem(1,'resp')" autofocus />
        </div>
      </div>
      <template #footer>
        <pm-Button v-if="this.env == 0 && responder_msg == 0"  label="Responder Mensagem ?" icon="pi pi-check" @click="responderMensagem" autofocus />
      </template>
    </pm-Dialog>
    <!-- <pm-Dialog  v-model:visible="modalVideo" :style="{}" :maximizable="true" :modal="true">
      <p style="font-weight: 700; font-size: 20px; text-align: center">A partir do dia 13/11/2023 iremos implementar a autenticação de 2 fatores(2FA) no login como demonstrado abaixo:</p>
      <iframe :width="screeSize" height="720" src="https://www.youtube.com/embed/ZrU2F1gFIh4" title="Passo a passo login com 2fa" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </pm-Dialog> -->
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Servidores } from "@/class/servidores.js";
import { Comunicacao } from "@/class/comunicacao";


import VaChart from '@/components/va-charts/VaChart.vue'
import Pagination from 'v-pagination-3';
import listaDeMensagem from "@/components/painel/listar_mensagens.vue";
import axios from 'axios'

import { useGlobalConfig } from 'vuestic-ui'

export default defineComponent({
  components: { VaChart, Pagination, listaDeMensagem},
  data () {
    return {
      home: {icon: 'pi pi-home', to: '#'},
      items_bread: [
        {label: 'Painel do Professor'},
      ],


      links:[],
      links2:[],

      env:null,
      mensagem:[],
      msgAll:[],
      msgAll2:[],
      msgRecebidas:[],
      popmsg:false,
      popmsg2:false,
      conteudo_mensagem:[],
      filterservidor:[],
      recebidas:1,

      escolha:1,
      responder_msg:0,
      resposta_servidor_id:0,

      pesquisaservidor:null,
      id_servidor_logado:null,
      currentPage: 1,
      modalVideo: false,
      screeSize:1280,
      items:[
        {
          label: 'Mensagens',
          items: [{
            label: 'Recebidas',
              icon: 'pi pi-refresh',
              command: () => {
                this.mensagemRecebidas(1);
              }
            },
            {
              label: 'Enviadas',
              icon: 'pi pi-times',
              command: () => {
                this.mensagemEnviadas(1);
              }
            }
          ]
        },
      ],
    }
  },
  computed: {

  },
  methods:{
    async graficoN1() {
        try {

        } catch (e) {
        }
    },

    turmasProf(){
        this.$router.push({ name: "turmas-Professor"});
    },

    async trocarStatus(d){
      const data = await Comunicacao.trocaStatusMensagem(d.id);

      this.mensagemRecebidasMensagensAbertas(1);
      this.mensagemRecebidas(1);
    },

    async paginas(n){
      if(n.url != null){
        if(!n.active){
          const data = await axios.get(n.url);
          this.msgRecebidas = [];
          this.msgAll = [];

          this.msgRecebidas = data.data.data;
          this.links = data.data.links;
          this.msgAll = this.msgRecebidas;
        }
      }
    },

    async paginasLidas(n){
      if(n.url != null){
        if(!n.active){
          const data = await axios.get(n.url);
          this.msgRecebidas = [];
          this.msgAll2 = [];

          this.msgRecebidas = data.data.data;
          this.links2 = data.data.links;
          this.msgAll2 = this.msgRecebidas;
        }
      }
    },

    async mensagemRecebidas(n){
      this.recebidas = 1;
      this.msgAll = [];
      const data = await Comunicacao.obtemTodosMsgRecebidosNaoLidas(n);
      this.msgRecebidas = data.data.data;
      this.links = data.data.links;
      this.msgAll = this.msgRecebidas;

    },

    async mensagemRecebidasMensagensAbertas(n){
      this.recebidas = 1;
      this.msgAll2 = [];
      const data = await Comunicacao.obtemTodosMsgRecebidosAbertas(n);
      this.msgRecebidas = data.data.data;
      this.links2 = data.data.links;
      this.msgAll2 = this.msgRecebidas;
    },

    async mensagemEnviadas(){
      this.recebidas = 0;
      this.msgAll = [];
      const data = await Comunicacao.obtemTodosMsgEnviadas(this.currentPage);
      this.msgRecebidas = data.data.data;
      this.links = data.data.links;
      this.msgAll = this.msgRecebidas;
    },

    abrirMensagem(data,env){
      if(env == 0){
        this.trocarStatus(data);
      }
      this.env = env;
      this.popmsg = true;
      this.conteudo_mensagem = data;
      this.resposta_servidor_id = data.servidor_id;
    },

    responderMensagem(){
      this.responder_msg = 1;
    },

    enviarMensagemModal(){
      this.popmsg2 = true;
    },

    async enviarMensagem(tipo, resposta){
      try {
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);
        const login_id = whoiam.data.usuario.id;
        this.id_do_servidor = whoiam

        let id_serv_resp = 0;

        if(this.mensagem.titulo == null){
          alert("O titulo está Vazio!");
          return false;
        }

        if(this.mensagem.msg == null){
          alert("O conteudo da mensagem está Vazio!");
          return false;
        }


        if(tipo == 1){
          if(resposta == "resp"){
            // envio ao servidor resposta de uma mensagem aberta
            const nv = {
              titulo: this.mensagem.titulo,
              msg: this.mensagem.msg,
              setor_de_recebimento: null,
              servidor_id: login_id,
              servidor_id_resposta: this.resposta_servidor_id,
              resposta_msg_id: this.conteudo_mensagem.id,
            };

            const data = await Comunicacao.enviarMensagem(nv,2);
          }else{
            // envio ao servidor não resposta de mensagem
              if(this.pesquisaservidor != null){
                id_serv_resp = this.pesquisaservidor.id;
              }

              if(id_serv_resp == 0){
                return false;
              }

              const nv = {
                titulo: this.mensagem.titulo,
                msg: this.mensagem.msg,
                setor_de_recebimento: null,
                servidor_id: login_id,
                servidor_id_resposta: id_serv_resp,
              };

              const data = await Comunicacao.enviarMensagem(nv,1);
          }

        }else if(tipo == 2){
          if(this.mensagem.setor == null){
            alert("Selecione o Setor onde será enviado a mensagem!");
            return false;
          }

          const nv = {
            titulo: this.mensagem.titulo,
            msg: this.mensagem.msg,
            setor_de_recebimento: this.mensagem.setor,
            servidor_id: login_id,
            servidor_id_resposta: id_serv_resp,
          };


          const data = await Comunicacao.enviarMensagem(nv,1);
        }

        this.mensagem.titulo = "";
        this.mensagem.msg = "";
        this.mensagem.setor = "";
        this.pesquisaservidor = "";


        this.mensagemRecebidasMensagensAbertas(1);
        this.mensagemRecebidas(1);
        this.mensagemEnviadas();
        this.popmsg2 = false;
        this.popmsg = false;
        this.responder_msg =0;
        this.resposta_servidor_id =0;
      } catch (e) {

      }
    },

    async pesquisarServidor(){
      try{
        if(this.pesquisaservidor != null && this.pesquisaservidor != ""){
          const data = await Servidores.pesqServidorCompleto(this.pesquisaservidor);
          this.filterservidor = data.data;
        }
      }catch(e){
      }
    },
  },
  async beforeMount() {
    // if (this.$router.options.history.state.back === '/auth/login') {
    //   if (screen.width < 1280) {
    //     this.screeSize = screen.width;
    //   }
    //   this.modalVideo = true
    // }
    await this.mensagemRecebidas(this.currentPage);
    await this.mensagemRecebidasMensagensAbertas(this.currentPage);
    const token = sessionStorage.getItem("token");
    const whoiam = await LoginInfo.WhoIam(token);
    this.id_servidor_logado = whoiam.data.usuario.id;

  }

})


</script>

<style scoped>

.cc {
  padding: 5px !important;
}
/*.tile-f h3 {
  color: #fff;
  font-weight: 700;
  font-size: 37px;
}*/
/*.tile-f p {
  color: #fff;
}*/
.tile-03 {
  background: #03a6f4;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  background-clip: padding-box;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.tile-03:hover {
  background: #0390d1;
}
  .chart {
    height: 400px;
  }
  .text-right {
    text-align: right;
  }
</style>

<style scoped>
  .chart {
    height: 400px;
  }
  .text-right {
    text-align: right;
  }
  .badge-prym{
    color: #fff;
    background-color: #28a0ff;
    font-size: 11px;
    font-weight: 500 !important;
  }
  .badge-seg{
    color: #fff;
    background-color: #d4d4d4;
    font-size: 11px;
    font-weight: 500 !important;
  }

  body {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 20px;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}
</style>
